import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Quacademylogo from "../images/QuAcademylogo.png"; // Adjust the path as necessary
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

export const navList = [
    { id: 1, name: "Home", link: "/" },
    { id: 2, name: "About Us", link: "/Aboutus" },
    { id: 3, name: "Courses", link: "/Courses" },
    { id: 4, name: "Enquiry", link: "/Enquiry" },
    { id: 5, name: "Become Our Trainee", link: "/BecomeOurTrainer" },
];

export const courseNames = [
    { id: 1, name: "Applied Quantum" },
    { id: 2, name: "Applied Artificial Intelligence" },
];



const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleNavigation = (link) => {
        setIsNavOpen(false);
        navigate(link, { replace: true });
    };

    return (
        <header className="w-full widthScreen flex justify-between px-5 items-center h-[6rem]">
            <img src={Quacademylogo} alt="logo" className="object-contain" style={{ width: 138, height: 80 }} />
            <div className="hidden sm:block">
                <nav className="flex justify-center items-center gap-2">
                    <ul className="flex flex-row justify-center items-center px-2 py-1 gap-2">
                        {navList.map((item) => {
                           const isActive = location.pathname === item.link;
                            return (
                                <li
                                    key={item.id}
                                    className={`px-4 py-2 md:text-[1rem] text-sm cursor-pointer rounded-[0.2rem] font-extralight ${
                                        isActive ? "text-text-dark bg-boxcolor" : "text-text-light hover:text-text-dark"
                                    }`}
                                    onClick={() => {
                                        scrollToTop();
                                        
                                      handleNavigation(item.link)}}
                                >
                                    {item.name}
                                </li>
                            );
                        })}
                    </ul>
                    <a
                        className="px-4 py-2 text-text-light md:text-[1rem] text-sm cursor-pointer font-extralight rounded-[0.5rem] bg-boxfill"
                        href="https://thequantum.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Quantum AI Global
                    </a>
                    <a
                        className="px-4 py-2 text-text-light md:text-[1rem] text-sm cursor-pointer font-extralight rounded-[0.5rem] bg-boxfill"
                        href="https://qulabs.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Qulabs
                    </a>
                </nav>
            </div>
            <button onClick={toggleNav} className="sm:hidden text-text-light px-4 py-2">
                <FaBars size={24} />
            </button>

            {isNavOpen && (
                <div className="sm:hidden absolute top-0 left-0 w-full bg-primary flex flex-col items-center z-10 py-10">
                    <button onClick={toggleNav} className="w-[80%] items-center flex justify-end">
                        <RxCross2 size={24} color="#ffffff" />
                    </button>
                    <nav className="flex flex-col items-center gap-2 mt-4">
                        <ul className="flex flex-col items-center px-2 py-1 gap-2">
                            {navList.map((item) => {
                                const isActive = location.pathname === item.link;
                                return (
                                    <li
                                        key={item.id}
                                        className={`px-4 py-2 text-sm cursor-pointer rounded-[0.2rem] font-extralight ${
                                            isActive ? "text-text-dark bg-boxcolor" : "text-text-light hover:text-text-dark"
                                        }`}
                                        onClick={() => handleNavigation(item.link)}
                                    >
                                        {item.name}
                                    </li>
                                );
                            })}
                        </ul>
                        <a
                            className="px-4 py-2 text-text-light text-sm cursor-pointer font-extralight rounded-[0.5rem] bg-boxfill"
                            href="https://thequantum.ai/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Quantum AI Global
                        </a>
                        <a
                            className="px-4 py-2 text-text-light text-sm cursor-pointer font-extralight rounded-[0.5rem] bg-boxfill"
                            href="https://qulabs.ai/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Qulabs
                        </a>
                    </nav>
                </div>
            )}
        </header>
    );
};

export default Header;
