
import './App.css';
import GradientDefs from "./components/GradientDefs";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Home from "./pages/home";
import Aboutus from './pages/aboutUs';
import Enquiry from './pages/enquiry';
import Courses from './pages/courses';
import BecomeOurTrainer from './pages/becomeOurTrainer';


function App() {
  return <BrowserRouter>
  <Routes>
 
  <Route path="/" element={<Home />} />
  <Route path="/Aboutus" element={<Aboutus />} />
      <Route path="/Enquiry" element={<Enquiry />} />
      <Route path="/Courses" element={<Courses />} />
      <Route path="/BecomeOurTrainer" element={<BecomeOurTrainer />} />
      

 

  </Routes>
  </BrowserRouter>

}

export default App;

