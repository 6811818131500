import React from 'react';

const PartnersBox = ({ img }) => {
    return (
        <div className="w-[14.75rem] h-[6.125rem] flex justify-center items-center">
            <img
                src={img}
                alt="logo"
                width={163}
                height={83}
                className="object-contain"
            />
        </div>
    );
};

export default PartnersBox;
