export const HomeIcons = [
    {
        id: 1,
        img: require("../../src/images/Expert-Led Courses.png"),
        title: "Expert-Led Courses",
        content: "Learn from our partner company product leaders and academic experts."
    },
    {
        id: 2,
        img: require("../../src/images/Hands-On Learning.png"),
        title: "Hands-On Learning",
        content: "Engage in real-world projects and practical exercises based on the commercial products built."
    },
    {
        id: 3,
        img: require("../../src/images/Cutting-Edge Curriculum.png"),
        title: "Cutting-Edge Curriculum",
        content: " A practical orientation to the latest advancements in quantum technology and Artificial Intelligence."
    },
    {
        id: 4,
        img: require("../../src/images/Flexible Learning.png"),
        title: "Flexible Learning",
        content: "Online, self-paced, and live sessions to suit your schedule."
    },

]

export const CourseIconsData = [

    {
        id: 1,
        img: "/images/E-learning.png",
        heading: "Duration",
        content: [
            {
                AppliedQuantum: "240 Hours",
                AppliedArtificialIntelligence: "240 Hours",
                // DataScience:"240 Hours",
            }

        ]
    },
    {
        id: 2,
        img: "/images/Clock.png",
        heading: "Theory",
        content: [
            {
                AppliedQuantum: "240 Hours",
                AppliedArtificialIntelligence: "240 Hours",
                // DataScience:"240 Hours",
            }

        ]
    },
    {
        id: 3,
        img: "/images/Discuss.png",
        heading: "Lab",
        content: [
            {
                AppliedQuantum: "240 Hours",
                AppliedArtificialIntelligence: "240 Hours",
                // DataScience:"240 Hours",
            }

        ]
    },
    {
        id: 4,
        img: "/images/Board.png",
        heading: "Project",
        content: [
            {
                AppliedQuantum: "240 Hours",
                AppliedArtificialIntelligence: "240 Hours",
                // DataScience:"240 Hours",
            }

        ]
    },


];









export const CourseName = [
    {
        id: 1,
        name: "Applied Quantum",
        content: "Understand the principles of quantum mechanics and applying them to solve problems in computing, communication, sensing and materials. This course covers theoretical understanding of the concepts with relevant hands-on practices.",
        img: require("../images/AppliedQuantum.png")
    },
    {
        id: 2,
        name: "Applied Artificial Intelligence",
        content: "Master the foundations of AI, including machine learning, neural networks, and deep learning. Learn to build intelligent systems that can analyze data, recognize patterns, and make decisions.",
        img: require("../images/AppliedArtificialIntelligence.png")
    },
    // {
    //     id:3,
    //     name:"Data Science",
    //     content:"Master the art of data manipulation, visualization, and statistical analysis to uncover insights and make data-driven decisions that drive business success.",
    //     img:"/images/DataScience.png"
    // },

];

export const Partners = [
    {
        id: 1,
        img: require("../images/Cellarian.png")

    },
    {
        id: 2,
        img: require("../images/Qulabs.png")

    },
    {
        id: 3,
        img: require("../images/FullSpectrum.png"),

    },
  
    {
        id: 4,
        img: require("../images/QuantumAI.png")

    },

];

export const AboutUsPartners = [

    {
        id: 1,
        img: require("../images/Qulabs.png"

 )   },

    {
        id: 2,
        img: require("../images/QuantumAI.png")

    },

];


export const Aboutus = [
    {
        id: 1,
        name: "Our Vision",
        img:require("../images/vision.png"),
        content: "We envision a world where advanced technological education is accessible to everyone, fostering innovation and driving progress across all sectors. Our goal is to create a global network of highly skilled individuals who are equipped to lead and innovate in the fields of AI and quantum in their own regions."
    },
    {
        id: 2,
        name: "Our Mission",
        img:require("../images/Mission.png"),
        content:"Our mission is to empower individuals with the knowledge and skills to navigate and innovate in the complex worlds of quantum computing and AI. We believe in bridging the gap between theory and practice, equipping our learners with hands-on experience and cutting-edge expertise to tackle real-world challenges."
    },
    {
        id: 3,
        name: "Our Commitment",
        img:require("../images/commitment.png"),
        content: "Whether you&rsquo;re a seasoned professional looking to upskill or a newcomer eager to embark on a journey into emerging technologies, QuAcademy provides a supportive environment where curiosity and ambition thrive. We are committed to your success towards achieving your educational and career aspirations."
    },

];


export const ProfileData = [
    {
        id: 1,
        name: "Mr. Sanjay Chittore",
        img: require("../images/SANJAY.png"),
       
        link: "https://www.linkedin.com/in/sanjaychittore/",
    },
    {
        id: 2,
        name: "Gopika Chaganti",
        img: require("../images/GOPIKA.png"),
       
        link: "https://www.linkedin.com/in/gopika-chaganti/",
    },
    {
        id: 3,
        name: "Prasad Boya",
        img: require("../images/Prasad.png"),
       
        link: "https://www.linkedin.com/in/prasad-boya-86b27731/",
    },
    {
        id: 4,
        name: "David Way",
        img: require("../images/DavidWay.png"),
        
        link: "https://www.linkedin.com/in/davidwaychc/",
    },
    {
        id: 5,
        name: "Micheal Lu",
        img: require("../images/MICHAEL.png"),
       
        link:"https://www.linkedin.com/in/michaellu3/",
    },
    {
        id: 6,
        name: "Dr Ravi Sarepalli",
        img: require("../images/RAVISARIPALLE.png"),
        
        link: "https://www.linkedin.com/in/ravisaripalle/",
    },
    {
        id: 7,
        name: "Dr Hemachandran",
        img: require("../images/HEMACHANDRAN.png"),
       
        link: "https://www.linkedin.com/in/drhemachandrank/",
    },
]


export const Cards = [
    { id: 1, name: "Satya Nadella, CEO of Microsoft", content: "“Quantum computing is poised to transform industries by solving problems that today’s computers cannot. It holds the potential to tackle some of the world’s toughest challenges.”" },
    { id: 2, name: "Andrew Ng, AI Researcher and Co-founder of Coursera", content: "“AI is the new electricity. Just as electricity transformed numerous industries a century ago, AI is now poised to do the same.”" },
    { id: 3, name: "Sam Altman, CEO of OpenAI",  content: "“Quantum computing has the potential to bring about breakthroughs that we can’t even fathom today, fundamentally altering the capabilities of AI systems.”" },
    { id: 4, name: "India",  content: "India recognizes the transformative potential of quantum technologies and has launched the National Mission on Quantum Technologies and Applications (NM-QTA). The government aims to build indigenous capabilities in quantum computing, communication, and sensing." },
    { id: 5, name: "United States",  content: "The U.S. government emphasizes quantum technology as a strategic priority for national security and economic competitiveness. Efforts include significant funding for quantum research through initiatives like the National Quantum Initiative." },
    { id: 6, name: "China",  content: "China views quantum technology as crucial for achieving global leadership in science and technology. The government has made substantial investments in quantum research and aims to build a comprehensive quantum industry ecosystem." },
    { id: 7, name: "European Union",  content: "The EU emphasizes AI development under ethical guidelines, focusing on responsible AI deployment, data protection, and human-centric AI. Initiatives like the AI Act aim to regulate AI applications within the EU." },
    { id: 8, name: "Anders Irbäck, Chair of the Nobel Committee for Physics",  content: "“It has become increasingly clear that a new kind of quantum technology is emerging. We can see that the laureates’ work with entangled states is of great importance, even beyond the fundamental questions about the interpretation of quantum mechanics.”" },
];



  export const Comments=[
    {
        id:1,
        name:'Narahari Tenneti',
       
        comment:"The AI and ML course at QuAcademy was a transformative experience. Having scientists and engineers with varied tech and academic expertise as instructors provided invaluable product development insights, enabling me to work directly on industry-relevant projects"
    },
    {
        id:2,
        name:'Milan K B',
        
        comment:"Learning from developers and researchers actively working in the field gave me a practical understanding and the confidence to tackle real-world quantum challenges. This course truly bridges the gap between theory and application."
    },
    {
        id:3,
        name:'Vinay Reddy',
       
        comment:"The applied AI and ML course at QuAcademy was instrumental in my career advancement. The opportunity to learn from professionals provided me with hands-on experience and insider knowledge on product development, making the transition to my aspiring role seamlessly."
    },
    {
        id:4,
        name:'Rahul Raj',
      
        comment:"The direct interaction with quantum hardware and software consultants and developers from QuAcademy partner companies offered me unique insights into product development, significantly enhancing my skills and understanding of the industry"
    },
   
  ]