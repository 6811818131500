import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import SimpleHeroSection from '../components/simpleHeroSection'
import { TraineeForm } from '../components/personalForm' 

export default function becomeOurTrainer() {
  return (
    <main className="philosopher bg-primary w-full flex flex-col justify-center items-center">
        
    <Header />
<div className="w-full widthScreen flex flex-col justify-center items-center">

<SimpleHeroSection title={"Enquiry"} content={"Submit your enquiry today and start your journey towards mastering Quantum and AI."}/>
            <TraineeForm/>
            <div
                    className="absolute -z-10 left-1/2 top-[75%] transform -translate-x-1/2 -translate-y-1/2 rounded-full md:w-[30rem] md:h-[30rem]"
                    style={{
                        background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                        filter: "blur(40px)",
                    }}
                ></div>


</div>
<Footer />
</main>
  )
}
