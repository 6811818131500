import React from 'react';

const TitleContent = ({ title, content }) => {
    return (
        <div className="w-full flex flex-col items-center justify-center">
            <div className="w-full md:w-[50rem] flex flex-col items-start justify-center gap-5">
                <h1 className="w-full font-medium md:text-4xl text-center text-2xl text-text-light">
                    {title}
                </h1>
                <p className="w-full md:text-[1rem] text-sm text-center font-thin text-text-dark">
                    {content}
                </p>
            </div>
        </div>
    );
};

export default TitleContent;
