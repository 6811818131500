// src/components/Footer.jsx
import React from 'react';
import GradientDefs from './GradientDefs';
import { useNavigate } from 'react-router-dom';
import { navList } from './header'; // Import the navList from Header
import { FaPhoneAlt, FaLinkedin, FaArrowRight } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { IoLocationSharp } from 'react-icons/io5';
import { IoMdMail } from 'react-icons/io';

const Footer = () => {
    const navigate = useNavigate();

    const handleLinkedInClick = () => {
        window.open("https://www.linkedin.com/company/qu-academy/", "_blank");
    };

    const handleTwitterClick = () => {
        window.open("https://x.com/i/flow/login?redirect_after_login=%2Fqu_academy", "_blank");
    };

    const handleNavigation = (link) => {
        navigate(link);
    };
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    };

    return (
        <div className="w-full widthScreen flex items-center justify-center pt-16 px-5">
            <footer className="w-full flex flex-col justify-center items-center p-3">
                <GradientDefs />
                <section className="w-full flex flex-col md:flex-row justify-between items-start gap-5 md:gap-16 md:h-[20rem]">
                    <div className="flex flex-col justify-center items-start gap-3">
                        <div className="px-2 font-medium text-[1rem] text-text-light">QUICK LINKS</div>
                        <nav>
                            <ul className="flex flex-col justify-center items-start px-2 py-1 gap-2">
                                {navList.map((item) => (
                                    <li
                                        key={item.id}
                                        className={`pt-1 md:text-[1rem] text-sm cursor-pointer font-extralight text-text-footer`}
                                        onClick={() => {
                                            scrollToTop();
                                            
                                          handleNavigation(item.link)}}
                                    >
                                        {item.name}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="flex flex-col justify-center items-start gap-3">
                        <div className="px-2 font-medium text-[1rem] text-text-light">CONTACT DETAILS</div>
                        <div className="flex flex-col justify-center items-start px-2 py-1 gap-2">
                            <div className="flex justify-center items-center gap-2">
                                <IoLocationSharp style={{ fill: "url(#gradient)" }} size={26} />
                                <p className="w-[15rem] md:text-[1rem] text-sm cursor-pointer font-extralight text-text-footer">
                                    Suite 305B, Capital Park, HITECH City Hyderabad, Telangana 500081
                                </p>
                            </div>
                            <div className="flex justify-center items-center gap-2">
                                <FaPhoneAlt style={{ fill: "url(#gradient)" }} size={26} />
                                <p className="w-[15rem] md:text-[1rem] text-sm cursor-pointer font-extralight text-text-footer">
                                    +91 8121027001
                                </p>
                            </div>
                            <div className="flex justify-center items-center gap-2">
                                <IoMdMail style={{ fill: "url(#gradient)" }} size={26} />
                                <p className="w-[15rem] md:text-[1rem] text-sm cursor-pointer font-extralight text-text-footer">
                                    info@quacademy.com
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between items-start h-full">
                        <div className="flex flex-col justify-center items-start gap-5 md:gap-8">
                            <div className="px-2 flex flex-col justify-center items-start gap-2">
                                <div className="px-2 font-medium text-[1rem] text-text-light">FOLLOW US</div>
                                <div className="flex flex-col justify-center items-start px-2 py-1 gap-2">
                                    <div className="flex justify-center items-center gap-2">
                                        <FaLinkedin style={{ fill: "url(#gradient)" }} size={26} onClick={handleLinkedInClick} />
                                        <p className="md:text-[1rem] text-sm cursor-pointer font-extralight text-text-footer">
                                            LinkedIn
                                        </p>
                                    </div>
                                    <div className="flex justify-center items-center gap-2">
                                        <FaSquareXTwitter style={{ fill: "url(#gradient)" }} size={26} onClick={handleTwitterClick} />
                                        <p className="md:text-[1rem] text-sm cursor-pointer font-extralight text-text-footer">
                                            Twitter
                                        </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div className="w-full flex justify-between items-center p-2">
                            <p className="text-[0.5rem] md:text-[0.7rem] font-extralight text-text-dark"> © QuAcademy, 2024</p>
                            <p className="text-[0.5rem] md:text-[0.7rem] font-extralight text-text-footer">All Rights Reserved| Terms and Conditions | Privacy Policy</p>

                        </div>
                    
                   
                    </div>
                </section>
            </footer>
        </div>
    );
};

export default Footer;
